import { ArrowRightIcon } from "@heroicons/react/24/outline"
const features = [
    {
      name: '12-Lead ECG.',
      description: "Provides a comprehensive view of the heart's electrical activity, enabling the detection of arrhythmias, ischemia, and other cardiac abnormalities.",
      icon: ArrowRightIcon,
    },
    {
      name: 'Height.',
      description: "Essential for calculating BMI, which helps in assessing the risk of cardiovascular diseases related to obesity.",
      icon: ArrowRightIcon,
    },
    {
      name: 'Weight.',
      description: "Key for determining BMI and monitoring changes that could indicate potential cardiovascular health issues.",
      icon: ArrowRightIcon,
    },
    {
      name: 'BMI.',
      description: "Offers a quick assessment of body fat, which is a significant risk factor for cardiovascular diseases.",
      icon: ArrowRightIcon,
    },
    {
      name: 'Structural Heart Health with Hemotag Vitals.',
      description: "Allows for detailed evaluation of heart structure and function.",
      icon: ArrowRightIcon,
    }
  ]

export default function HeaderSection() {
    return (
      <div className="bg-white px-6 py-24 sm:py-20 lg:px-8">
        <div className="mx-auto max-w-2xl text-center">
          <p className="text-base font-semibold leading-7 text-indigo-600">Get the help you need</p>
          <h2 className="mt-2 text-4xl font-bold tracking-tight text-gray-900 sm:text-6xl">Complimentary Cardiac Assessment</h2>
          <p className="mt-6 text-lg leading-8 text-gray-600">
            Trialogy is pleased to offer a complimentary cardiac asessment to out community. Enter your information below to get started.
          </p>
        </div>
        <div className="mx-auto mt-16 max-w-7xl px-6 sm:mt-20 md:mt-24 lg:px-8">
            <h2 className="mt-2 text-2xl tracking-tight text-gray-600 sm:text-3xl pb-10">The cardiac screening includes:</h2>
            <dl className="mx-auto grid max-w-2xl grid-cols-1 gap-x-6 gap-y-10 text-base leading-7 text-gray-600 sm:grid-cols-2 lg:mx-0 lg:max-w-none lg:grid-cols-3 lg:gap-x-8 lg:gap-y-16">
            {features.map((feature) => (
                <div key={feature.name} className="relative pl-9">
                <dt className="inline font-semibold text-gray-900">
                    <feature.icon className="absolute left-1 top-1 h-5 w-5 text-indigo-600" aria-hidden="true" />
                    {feature.name}
                </dt>{' '}
                <dd className="inline">{feature.description}</dd>
                </div>
            ))}
            </dl>
        </div>
      </div>
    )
  }
  