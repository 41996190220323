import { AcademicCapIcon, UserPlusIcon, CheckBadgeIcon, MagnifyingGlassIcon, 
          ComputerDesktopIcon, NewspaperIcon, DevicePhoneMobileIcon, PlusCircleIcon } from '@heroicons/react/20/solid'

const featureData = [
    {
      title: "Soutions for Sponsors",
      subtitle: "Decentralize your clinical trial",
      description: "Research centers have multiple limitations. Trialogy has developed a way to overcome these limitations to ensure trial success.",
      imageSrc: "https://trialogy.blob.core.windows.net/website-images/solutions-for-sponsors.jpg",
      bookmark: "sponsors",
      features: [
        {
          name: 'Streamlined Trials.',
          description:
            'Starting new trials can be resource intensive and time consuming. Trialogy streamlines the process to start the process fast and efficiently.',
          icon: AcademicCapIcon,
        },
        {
          name: 'Increase Patient Access.',
          description: 'Hospitals have a limited supply of patients. Increase the reach of your trial by partnering with Trialogy.',
          icon: UserPlusIcon,
        },
        {
          name: 'Improved Enrollments.',
          description: 'Most Hospitals are understaffed and run multiple trials at once. Trialogy allows to tap into a new network of providers and patients.',
          icon: CheckBadgeIcon,
        },
      ]
    },
    {
      title: "Solutions for Physicians",
      subtitle: "Improve patient outcomes",
      description: "Physicians looking to improve patient outcomes and satisfaction are a perfect fit for clinical trials.",
      imageSrc: "https://trialogy.blob.core.windows.net/website-images/solutions-for-physicians.jpg",
      bookmark: "providers",
      features: [
        {
          name: 'Improve Patient Experience.',
          description:
            'Clinical trials allows the ability to introduce the treatment methods, improving patient experience and satisfaction.',
          icon: PlusCircleIcon,
        },
        {
          name: 'Acess New Technologies.',
          description: 'Get access to the latest technology to treat your patients.',
          icon: DevicePhoneMobileIcon,
        },
        {
          name: 'Generate New Revenue Streams.',
          description: 'Starting a new clinical trial benefits everyone involved.',
          icon: CheckBadgeIcon,
        },
      ]
    },
    {
      title: "Enroll in a Clinical Trial",
      subtitle: "Advance your health",
      description: "We're increasing access to clinical research through our network of doctors.",
      imageSrc: "https://trialogy.blob.core.windows.net/website-images/enroll-clinical-trial.jpg",
      bookmark: "patients",
      features: [
        {
          name: 'New Discoveries.',
          description:
            'Clinical trials allow patients to access life-saving education and treatment.',
          icon: NewspaperIcon,
        },
        {
          name: 'Search and Enroll.',
          description: 'Arming patients with the ability to search and enroll in clinical trials improves trial completion rates.',
          icon: MagnifyingGlassIcon,
        },
        {
          name: 'Remote Monitoring.',
          description: 'Some trials allow patients for a hybrid or remote management, improving the patient experience.',
          icon: ComputerDesktopIcon,
        },
      ]
    },
  ];

export default function FeaturesTwoSection() {
  return (
    <div className="overflow-hidden bg-white py-24 sm:py-32">
      <div className="mx-auto max-w-7xl md:px-6 lg:px-8">
      {featureData.map((feature, index) => (
        <div key={index + feature.bookmark} className="grid grid-cols-1 gap-x-8 gap-y-16 sm:gap-y-20 lg:grid-cols-2 lg:items-start mb-20" id={feature.bookmark}>
          <div className="px-6 lg:px-0 lg:pr-4 lg:pt-4">
            <div className="mx-auto max-w-2xl lg:mx-0 lg:max-w-lg">
              <p className="text-base font-semibold leading-7 text-emerald-600">{feature.subtitle}</p>
              <h2 className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">{feature.title}</h2>
              <p className="mt-6 text-lg leading-8 text-gray-600">
                {feature.description}
              </p>
              <dl className="mt-10 max-w-xl space-y-8 text-base leading-7 text-gray-600 lg:max-w-none">
                {feature.features.map((f) => (
                  <div key={feature.name} className="relative pl-9">
                    <dt className="inline font-semibold text-gray-900">
                      <f.icon className="absolute left-1 top-1 h-5 w-5 text-emerald-600" aria-hidden="true" />
                      {f.name}
                    </dt>{' '}
                    <dd className="inline">{f.description}</dd>
                  </div>
                ))}
              </dl>
            </div>
          </div>
          <div className="sm:px-6 lg:px-0">
            <div className="relative isolate overflow-hidden bg-emerald-500 px-6 pt-8 sm:mx-auto sm:max-w-2xl sm:rounded-3xl sm:pl-16 sm:pr-0 sm:pt-16 lg:mx-0 lg:max-w-none">
              <div
                className="absolute -inset-y-px -left-3 -z-10 w-full origin-bottom-left skew-x-[-30deg] bg-emerald-100 opacity-20 ring-1 ring-inset ring-white"
                aria-hidden="true"
              />
              <div className="mx-auto max-w-2xl sm:mx-0 sm:max-w-none">
                <img
                  src={feature.imageSrc}
                  alt={feature.title}
                  width={2432}
                  height={1442}
                  className="-mb-12 w-[37rem] max-w-none rounded-tl-xl bg-gray-800 ring-1 ring-white/10"
                />
              </div>
              <div
                className="pointer-events-none absolute inset-0 ring-1 ring-inset ring-black/10 sm:rounded-3xl"
                aria-hidden="true"
              />
            </div>
          </div>
        </div>
        ))}
      </div>
    </div>
  )
}
