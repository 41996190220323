import Header from "./Header";
import Footer from "./Footer";
import HeaderSection from "./HeaderSection";

const styles = {
  width: "100%", 
  height: "1000px"
}

export default function EnrollSection() {
  return (
    <div className="min-h-screen bg-gray-100 text-gray-900">
      <Header />
      <HeaderSection />
      <main>
        <iframe src='https://outlook.office365.com/owa/calendar/FreeHeartScreeningBooking@aventusoft.com/bookings/' style={styles} title="heartScreening"></iframe>
      </main>
      <Footer />
    </div>
  )
}