import { UserPlusIcon, BuildingOfficeIcon, UsersIcon } from '@heroicons/react/24/outline'

const features = [
  {
    name: 'Solutions for Sponsors',
    description:
      'Discover how Trialogy can streamline your clinical trial and open new patient markets.',
    href: '#sponsors',
    icon: UserPlusIcon,
  },
  {
    name: 'Solutions for Physicians',
    description:
      'Trailogy can provide a new revenue stream for your practice while improving patient satisfaction.',
    href: '#providers',
    icon: BuildingOfficeIcon,
  },
  {
    name: 'Become a Patient',
    description:
      'See how you can benefit from clinical trials happening near you.',
    href: '#patients',
    icon: UsersIcon,
  },
]

export default function SelectionSection() {
  return (
    <div className="bg-green-950 py-15 sm:py-24">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto mt-16 max-w-2xl sm:mt-20 lg:mt-24 lg:max-w-none">
          <dl className="grid max-w-xl grid-cols-1 gap-x-8 gap-y-16 lg:max-w-none lg:grid-cols-3">
            {features.map((feature) => (
              <div key={feature.name} className="flex flex-col">
                <dt className="text-base font-semibold leading-7 text-white">
                  <div className="mb-6 flex h-10 w-10 items-center justify-center rounded-lg bg-emerald-500">
                    <feature.icon className="h-6 w-6 text-white" aria-hidden="true" />
                  </div>
                  {feature.name}
                </dt>
                <dd className="mt-1 flex flex-auto flex-col text-base leading-7 text-gray-300">
                  <p className="flex-auto">{feature.description}</p>
                  <p className="mt-6">
                    <a href={feature.href} className="text-sm font-semibold leading-6 text-emerald-400">
                      Learn more <span aria-hidden="true">→</span>
                    </a>
                  </p>
                </dd>
              </div>
            ))}
          </dl>
        </div>
      </div>
    </div>
  )
}
