export default function HeroSection() {
    return (
        <div className="bg-green-950 pt-7">
            <div className="mx-auto mt-8 max-w-7xl px-6 sm:mt-16 lg:px-8">
            <h2 className="text-center text-lg font-semibold leading-8 text-white">
                Clinical partners
            </h2>
            <div className="mx-auto mt-10 grid max-w-lg grid-cols-4 items-center gap-x-8 gap-y-10 sm:max-w-xl sm:grid-cols-6 sm:gap-x-10 lg:mx-0 lg:max-w-none lg:grid-cols-5">
                <img
                className="col-span-2 max-h-12 w-full object-contain lg:col-span-1"
                src="https://trialogy.blob.core.windows.net/website-images/cleveland-clinic-logo-black-and-white.png"
                alt="Cleveland Clinic"
                width={158}
                height={48}
                />
                <img
                className="col-span-2 max-h-12 w-full object-contain lg:col-span-1"
                src="https://trialogy.blob.core.windows.net/website-images/mhs-logo-white.png"
                alt="Memorial Health"
                width={158}
                height={48}
                />
                <img
                className="col-span-2 max-h-12 w-full object-contain lg:col-span-1"
                src="https://trialogy.blob.core.windows.net/website-images/Broward_Health_logo-white-green.svg"
                alt="Broward Health"
                width={158}
                height={48}
                />
                <img
                className="col-span-2 max-h-12 w-full object-contain sm:col-start-2 lg:col-span-1"
                src="https://trialogy.blob.core.windows.net/website-images/MRI-logo-white.png"
                alt="Metabolic Research Institute"
                width={158}
                height={48}
                />
                <img
                className="col-span-2 col-start-2 max-h-12 w-full object-contain sm:col-start-auto lg:col-span-1"
                src="https://trialogy.blob.core.windows.net/website-images/um-logo-white.png"
                alt="University of Miami"
                width={158}
                height={48}
                />
            </div>
            </div>
        </div>
    )
}