import React from 'react';
import Header from './components/Header';
import Footer from './components/Footer';
import HeroSection from './components/HeroSection';
import FeatureSection from './components/FeatureSection';
import ContactSection from './components/ContactSection';
import SelectionSection from './components/SelectionSection';
import FeaturesTwoSection from './components/FeaturesTwoSection';
import CtaSection from './components/CtaSection.js';
import LogoCloud from './components/LogoCloud';

function App() {
  return (
    <div className="min-h-screen bg-gray-100 text-gray-900">
      <Header />
      <main>
        <HeroSection />
        <LogoCloud />
        <SelectionSection />
        <FeatureSection />
        <CtaSection />
        <FeaturesTwoSection />
        <ContactSection />
      </main>
      <Footer />
    </div>
  );
}

export default App;